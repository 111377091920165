"use strict";

var _preact = require("preact");

var formatSeconds = require("./formatSeconds.js");

function RecordingLength(_ref) {
  var recordingLengthSeconds = _ref.recordingLengthSeconds,
      i18n = _ref.i18n;
  var formattedRecordingLengthSeconds = formatSeconds(recordingLengthSeconds);
  return (0, _preact.h)("span", {
    "aria-label": i18n('recordingLength', {
      recording_length: formattedRecordingLengthSeconds
    })
  }, formattedRecordingLengthSeconds);
}

module.exports = RecordingLength;