function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var _require = require('preact'),
    h = _require.h;

var classNames = require('classnames');

var ItemIcon = require('./components/ItemIcon');

var GridListItem = require('./components/GridLi');

var ListItem = require('./components/ListLi');

module.exports = function (props) {
  var author = props.author;
  var itemIconString = props.getItemIcon();
  var className = classNames('uppy-ProviderBrowserItem', {
    'uppy-ProviderBrowserItem--selected': props.isChecked
  }, {
    'uppy-ProviderBrowserItem--disabled': props.isDisabled
  }, {
    'uppy-ProviderBrowserItem--noPreview': itemIconString === 'video'
  });
  var itemIconEl = h(ItemIcon, {
    itemIconString: itemIconString
  });

  switch (props.viewType) {
    case 'grid':
      return h(GridListItem, _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }));

    case 'list':
      return h(ListItem, _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }));

    case 'unsplash':
      return h(GridListItem, _extends({}, props, {
        className: className,
        itemIconEl: itemIconEl
      }), h("a", {
        href: "".concat(author.url, "?utm_source=Companion&utm_medium=referral"),
        target: "_blank",
        rel: "noopener noreferrer",
        className: "uppy-ProviderBrowserItem-author"
      }, author.name));

    default:
      throw new Error("There is no such type ".concat(props.viewType));
  }
};