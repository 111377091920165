function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _require = require('preact'),
    h = _require.h;

var classNames = require('classnames');

var remoteFileObjToLocal = require('@uppy/utils/lib/remoteFileObjToLocal');

var Filter = require('./Filter');

var FooterActions = require('./FooterActions');

var Item = require('./Item/index');

var VIRTUAL_SHARED_DIR = 'shared-with-me';

function Browser(props) {
  var currentSelection = props.currentSelection,
      folders = props.folders,
      files = props.files,
      uppyFiles = props.uppyFiles,
      viewType = props.viewType,
      headerComponent = props.headerComponent,
      showBreadcrumbs = props.showBreadcrumbs,
      isChecked = props.isChecked,
      _toggleCheckbox = props.toggleCheckbox,
      handleScroll = props.handleScroll,
      showTitles = props.showTitles,
      i18n = props.i18n,
      validateRestrictions = props.validateRestrictions,
      showFilter = props.showFilter,
      filterQuery = props.filterQuery,
      filterInput = props.filterInput,
      getNextFolder = props.getNextFolder,
      cancel = props.cancel,
      done = props.done,
      columns = props.columns;
  var selected = currentSelection.length;
  return h("div", {
    className: classNames('uppy-ProviderBrowser', "uppy-ProviderBrowser-viewType--".concat(viewType))
  }, h("div", {
    className: "uppy-ProviderBrowser-header"
  }, h("div", {
    className: classNames('uppy-ProviderBrowser-headerBar', !showBreadcrumbs && 'uppy-ProviderBrowser-headerBar--simple')
  }, headerComponent)), showFilter && h(Filter, {
    i18n: i18n,
    filterQuery: filterQuery,
    filterInput: filterInput
  }), function () {
    if (!folders.length && !files.length) {
      return h("div", {
        className: "uppy-Provider-empty"
      }, props.i18n('noFilesFound'));
    }

    return h("div", {
      className: "uppy-ProviderBrowser-body"
    }, h("ul", {
      className: "uppy-ProviderBrowser-list",
      onScroll: handleScroll,
      role: "listbox" // making <ul> not focusable for firefox
      ,
      tabIndex: "-1"
    }, folders.map(function (folder) {
      var _isChecked;

      return Item({
        columns: columns,
        showTitles: showTitles,
        viewType: viewType,
        i18n: i18n,
        id: folder.id,
        title: folder.name,
        getItemIcon: function getItemIcon() {
          return folder.icon;
        },
        isChecked: isChecked(folder),
        toggleCheckbox: function toggleCheckbox(event) {
          return _toggleCheckbox(event, folder);
        },
        type: 'folder',
        isDisabled: (_isChecked = isChecked(folder)) == null ? void 0 : _isChecked.loading,
        isCheckboxDisabled: folder.id === VIRTUAL_SHARED_DIR,
        handleFolderClick: function handleFolderClick() {
          return getNextFolder(folder);
        }
      });
    }), files.map(function (file) {
      var validated = validateRestrictions(remoteFileObjToLocal(file), [].concat(_toConsumableArray(uppyFiles), _toConsumableArray(currentSelection)));
      return Item({
        id: file.id,
        title: file.name,
        author: file.author,
        getItemIcon: function getItemIcon() {
          return file.icon;
        },
        isChecked: isChecked(file),
        toggleCheckbox: function toggleCheckbox(event) {
          return _toggleCheckbox(event, file);
        },
        columns: columns,
        showTitles: showTitles,
        viewType: viewType,
        i18n: i18n,
        type: 'file',
        isDisabled: !validated.result && !isChecked(file),
        restrictionReason: validated.reason
      });
    })));
  }(), selected > 0 && h(FooterActions, {
    selected: selected,
    done: done,
    cancel: cancel,
    i18n: i18n
  }));
}

module.exports = Browser;