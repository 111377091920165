import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.async-iterator.js";
import "core-js/modules/es.symbol.has-instance.js";
import "core-js/modules/es.symbol.is-concat-spreadable.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.match.js";
import "core-js/modules/es.symbol.match-all.js";
import "core-js/modules/es.symbol.replace.js";
import "core-js/modules/es.symbol.search.js";
import "core-js/modules/es.symbol.species.js";
import "core-js/modules/es.symbol.split.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.symbol.to-string-tag.js";
import "core-js/modules/es.symbol.unscopables.js";
import "core-js/modules/es.aggregate-error.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.copy-within.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.of.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.species.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.has-instance.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.global-this.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.json.to-string-tag.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.math.acosh.js";
import "core-js/modules/es.math.asinh.js";
import "core-js/modules/es.math.atanh.js";
import "core-js/modules/es.math.cbrt.js";
import "core-js/modules/es.math.clz32.js";
import "core-js/modules/es.math.cosh.js";
import "core-js/modules/es.math.expm1.js";
import "core-js/modules/es.math.fround.js";
import "core-js/modules/es.math.hypot.js";
import "core-js/modules/es.math.imul.js";
import "core-js/modules/es.math.log10.js";
import "core-js/modules/es.math.log1p.js";
import "core-js/modules/es.math.log2.js";
import "core-js/modules/es.math.sign.js";
import "core-js/modules/es.math.sinh.js";
import "core-js/modules/es.math.tanh.js";
import "core-js/modules/es.math.to-string-tag.js";
import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.epsilon.js";
import "core-js/modules/es.number.is-finite.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.is-safe-integer.js";
import "core-js/modules/es.number.max-safe-integer.js";
import "core-js/modules/es.number.min-safe-integer.js";
import "core-js/modules/es.number.parse-float.js";
import "core-js/modules/es.number.parse-int.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-getter.js";
import "core-js/modules/es.object.define-setter.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.get-own-property-names.js";
import "core-js/modules/es.object.get-prototype-of.js";
import "core-js/modules/es.object.is.js";
import "core-js/modules/es.object.is-extensible.js";
import "core-js/modules/es.object.is-frozen.js";
import "core-js/modules/es.object.is-sealed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.lookup-getter.js";
import "core-js/modules/es.object.lookup-setter.js";
import "core-js/modules/es.object.prevent-extensions.js";
import "core-js/modules/es.object.seal.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.all-settled.js";
import "core-js/modules/es.promise.any.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.reflect.apply.js";
import "core-js/modules/es.reflect.construct.js";
import "core-js/modules/es.reflect.define-property.js";
import "core-js/modules/es.reflect.delete-property.js";
import "core-js/modules/es.reflect.get.js";
import "core-js/modules/es.reflect.get-own-property-descriptor.js";
import "core-js/modules/es.reflect.get-prototype-of.js";
import "core-js/modules/es.reflect.has.js";
import "core-js/modules/es.reflect.is-extensible.js";
import "core-js/modules/es.reflect.own-keys.js";
import "core-js/modules/es.reflect.prevent-extensions.js";
import "core-js/modules/es.reflect.set.js";
import "core-js/modules/es.reflect.set-prototype-of.js";
import "core-js/modules/es.reflect.to-string-tag.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.code-point-at.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.from-code-point.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.match-all.js";
import "core-js/modules/es.string.pad-end.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.raw.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.trim-end.js";
import "core-js/modules/es.string.trim-start.js";
import "core-js/modules/es.string.anchor.js";
import "core-js/modules/es.string.big.js";
import "core-js/modules/es.string.blink.js";
import "core-js/modules/es.string.bold.js";
import "core-js/modules/es.string.fixed.js";
import "core-js/modules/es.string.fontcolor.js";
import "core-js/modules/es.string.fontsize.js";
import "core-js/modules/es.string.italics.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.string.small.js";
import "core-js/modules/es.string.strike.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/es.string.sup.js";
import "core-js/modules/es.typed-array.float32-array.js";
import "core-js/modules/es.typed-array.float64-array.js";
import "core-js/modules/es.typed-array.int8-array.js";
import "core-js/modules/es.typed-array.int16-array.js";
import "core-js/modules/es.typed-array.int32-array.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.uint8-clamped-array.js";
import "core-js/modules/es.typed-array.uint16-array.js";
import "core-js/modules/es.typed-array.uint32-array.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.from.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.of.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.weak-map.js";
import "core-js/modules/es.weak-set.js";
import "core-js/modules/esnext.aggregate-error.js";
import "core-js/modules/esnext.array.at.js";
import "core-js/modules/esnext.array.filter-out.js";
import "core-js/modules/esnext.array.find-last.js";
import "core-js/modules/esnext.array.find-last-index.js";
import "core-js/modules/esnext.array.is-template-object.js";
import "core-js/modules/esnext.array.last-index.js";
import "core-js/modules/esnext.array.last-item.js";
import "core-js/modules/esnext.array.unique-by.js";
import "core-js/modules/esnext.async-iterator.constructor.js";
import "core-js/modules/esnext.async-iterator.as-indexed-pairs.js";
import "core-js/modules/esnext.async-iterator.drop.js";
import "core-js/modules/esnext.async-iterator.every.js";
import "core-js/modules/esnext.async-iterator.filter.js";
import "core-js/modules/esnext.async-iterator.find.js";
import "core-js/modules/esnext.async-iterator.flat-map.js";
import "core-js/modules/esnext.async-iterator.for-each.js";
import "core-js/modules/esnext.async-iterator.from.js";
import "core-js/modules/esnext.async-iterator.map.js";
import "core-js/modules/esnext.async-iterator.reduce.js";
import "core-js/modules/esnext.async-iterator.some.js";
import "core-js/modules/esnext.async-iterator.take.js";
import "core-js/modules/esnext.async-iterator.to-array.js";
import "core-js/modules/esnext.bigint.range.js";
import "core-js/modules/esnext.composite-key.js";
import "core-js/modules/esnext.composite-symbol.js";
import "core-js/modules/esnext.global-this.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.as-indexed-pairs.js";
import "core-js/modules/esnext.iterator.drop.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.from.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/esnext.iterator.take.js";
import "core-js/modules/esnext.iterator.to-array.js";
import "core-js/modules/esnext.map.delete-all.js";
import "core-js/modules/esnext.map.emplace.js";
import "core-js/modules/esnext.map.every.js";
import "core-js/modules/esnext.map.filter.js";
import "core-js/modules/esnext.map.find.js";
import "core-js/modules/esnext.map.find-key.js";
import "core-js/modules/esnext.map.from.js";
import "core-js/modules/esnext.map.group-by.js";
import "core-js/modules/esnext.map.includes.js";
import "core-js/modules/esnext.map.key-by.js";
import "core-js/modules/esnext.map.key-of.js";
import "core-js/modules/esnext.map.map-keys.js";
import "core-js/modules/esnext.map.map-values.js";
import "core-js/modules/esnext.map.merge.js";
import "core-js/modules/esnext.map.of.js";
import "core-js/modules/esnext.map.reduce.js";
import "core-js/modules/esnext.map.some.js";
import "core-js/modules/esnext.map.update.js";
import "core-js/modules/esnext.map.update-or-insert.js";
import "core-js/modules/esnext.map.upsert.js";
import "core-js/modules/esnext.math.clamp.js";
import "core-js/modules/esnext.math.deg-per-rad.js";
import "core-js/modules/esnext.math.degrees.js";
import "core-js/modules/esnext.math.fscale.js";
import "core-js/modules/esnext.math.iaddh.js";
import "core-js/modules/esnext.math.imulh.js";
import "core-js/modules/esnext.math.isubh.js";
import "core-js/modules/esnext.math.rad-per-deg.js";
import "core-js/modules/esnext.math.radians.js";
import "core-js/modules/esnext.math.scale.js";
import "core-js/modules/esnext.math.seeded-prng.js";
import "core-js/modules/esnext.math.signbit.js";
import "core-js/modules/esnext.math.umulh.js";
import "core-js/modules/esnext.number.from-string.js";
import "core-js/modules/esnext.number.range.js";
import "core-js/modules/esnext.object.has-own.js";
import "core-js/modules/esnext.object.iterate-entries.js";
import "core-js/modules/esnext.object.iterate-keys.js";
import "core-js/modules/esnext.object.iterate-values.js";
import "core-js/modules/esnext.observable.js";
import "core-js/modules/esnext.promise.all-settled.js";
import "core-js/modules/esnext.promise.any.js";
import "core-js/modules/esnext.promise.try.js";
import "core-js/modules/esnext.reflect.define-metadata.js";
import "core-js/modules/esnext.reflect.delete-metadata.js";
import "core-js/modules/esnext.reflect.get-metadata.js";
import "core-js/modules/esnext.reflect.get-metadata-keys.js";
import "core-js/modules/esnext.reflect.get-own-metadata.js";
import "core-js/modules/esnext.reflect.get-own-metadata-keys.js";
import "core-js/modules/esnext.reflect.has-metadata.js";
import "core-js/modules/esnext.reflect.has-own-metadata.js";
import "core-js/modules/esnext.reflect.metadata.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.from.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.of.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/esnext.string.at.js";
import "core-js/modules/esnext.string.code-points.js";
import "core-js/modules/esnext.string.match-all.js";
import "core-js/modules/esnext.string.replace-all.js";
import "core-js/modules/esnext.symbol.async-dispose.js";
import "core-js/modules/esnext.symbol.dispose.js";
import "core-js/modules/esnext.symbol.matcher.js";
import "core-js/modules/esnext.symbol.metadata.js";
import "core-js/modules/esnext.symbol.observable.js";
import "core-js/modules/esnext.symbol.pattern-match.js";
import "core-js/modules/esnext.symbol.replace-all.js";
import "core-js/modules/esnext.typed-array.at.js";
import "core-js/modules/esnext.typed-array.filter-out.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
import "core-js/modules/esnext.typed-array.unique-by.js";
import "core-js/modules/esnext.weak-map.delete-all.js";
import "core-js/modules/esnext.weak-map.from.js";
import "core-js/modules/esnext.weak-map.of.js";
import "core-js/modules/esnext.weak-map.emplace.js";
import "core-js/modules/esnext.weak-map.upsert.js";
import "core-js/modules/esnext.weak-set.add-all.js";
import "core-js/modules/esnext.weak-set.delete-all.js";
import "core-js/modules/esnext.weak-set.from.js";
import "core-js/modules/esnext.weak-set.of.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.queue-microtask.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";

require('whatwg-fetch');

require('abortcontroller-polyfill/dist/polyfill-patch-fetch'); // Order matters: AbortController needs fetch which needs Promise.


require('md-gum-polyfill');

var ResizeObserver = require('resize-observer-polyfill');

if (typeof window.ResizeObserver !== 'function') window.ResizeObserver = ResizeObserver; // Needed for Babel

require("regenerator-runtime/runtime");

require('./bundle.js');