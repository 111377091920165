'use strict';

var Uppy = require('./Uppy');

var UIPlugin = require('./UIPlugin');

var BasePlugin = require('./BasePlugin');

var _require = require('./loggers'),
    debugLogger = _require.debugLogger;

module.exports = Uppy;
module.exports.Uppy = Uppy;
module.exports.UIPlugin = UIPlugin;
module.exports.BasePlugin = BasePlugin;
module.exports.debugLogger = debugLogger;