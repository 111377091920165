var _require = require('preact'),
    h = _require.h,
    Fragment = _require.Fragment;

var Breadcrumb = function Breadcrumb(props) {
  var getFolder = props.getFolder,
      title = props.title,
      isLast = props.isLast;
  return h(Fragment, null, h("button", {
    type: "button",
    className: "uppy-u-reset",
    onClick: getFolder
  }, title), !isLast ? ' / ' : '');
};

module.exports = function (props) {
  var _getFolder = props.getFolder,
      title = props.title,
      breadcrumbsIcon = props.breadcrumbsIcon,
      directories = props.directories;
  return h("div", {
    className: "uppy-Provider-breadcrumbs"
  }, h("div", {
    className: "uppy-Provider-breadcrumbsIcon"
  }, breadcrumbsIcon), directories.map(function (directory, i) {
    return h(Breadcrumb, {
      key: directory.id,
      getFolder: function getFolder() {
        return _getFolder(directory.id);
      },
      title: i === 0 ? title : directory.title,
      isLast: i + 1 === directories.length
    });
  }));
};