module.exports = function getFileName(fileType, fileDescriptor) {
  if (fileDescriptor.name) {
    return fileDescriptor.name;
  }

  if (fileType.split('/')[0] === 'image') {
    return "".concat(fileType.split('/')[0], ".").concat(fileType.split('/')[1]);
  }

  return 'noname';
};