"use strict";

var _preact = require("preact");

var _hooks = require("preact/hooks");
/* eslint-disable jsx-a11y/media-has-caption */


var RecordButton = require("./RecordButton.js");

var RecordingLength = require("./RecordingLength.js");

var AudioSourceSelect = require("./AudioSourceSelect.js");

var AudioOscilloscope = require("./audio-oscilloscope/index.js");

var SubmitButton = require("./SubmitButton.js");

var DiscardButton = require("./DiscardButton.js");

function RecordingScreen(props) {
  var stream = props.stream,
      recordedAudio = props.recordedAudio,
      onStop = props.onStop,
      recording = props.recording,
      supportsRecording = props.supportsRecording,
      audioSources = props.audioSources,
      showAudioSourceDropdown = props.showAudioSourceDropdown,
      onSubmit = props.onSubmit,
      i18n = props.i18n,
      onStartRecording = props.onStartRecording,
      onStopRecording = props.onStopRecording,
      onDiscardRecordedAudio = props.onDiscardRecordedAudio,
      recordingLengthSeconds = props.recordingLengthSeconds;
  var canvasEl = (0, _hooks.useRef)(null);
  var oscilloscope = (0, _hooks.useRef)(null); // componentDidMount / componentDidUnmount

  (0, _hooks.useEffect)(function () {
    return function () {
      oscilloscope.current = null;
      onStop();
    };
  }, [onStop]); // componentDidUpdate

  (0, _hooks.useEffect)(function () {
    if (!recordedAudio) {
      oscilloscope.current = new AudioOscilloscope(canvasEl.current, {
        canvas: {
          width: 600,
          height: 600
        },
        canvasContext: {
          lineWidth: 2,
          fillStyle: 'rgb(0,0,0)',
          strokeStyle: 'green'
        }
      });
      oscilloscope.current.draw();

      if (stream) {
        var audioContext = new AudioContext();
        var source = audioContext.createMediaStreamSource(stream);
        oscilloscope.current.addSource(source);
      }
    }
  }, [recordedAudio, stream]);
  var hasRecordedAudio = recordedAudio != null;
  var shouldShowRecordButton = !hasRecordedAudio && supportsRecording;
  var shouldShowAudioSourceDropdown = showAudioSourceDropdown && !hasRecordedAudio && audioSources && audioSources.length > 1;
  return (0, _preact.h)("div", {
    className: "uppy-Audio-container"
  }, (0, _preact.h)("div", {
    className: "uppy-Audio-audioContainer"
  }, hasRecordedAudio ? (0, _preact.h)("audio", {
    className: "uppy-Audio-player",
    controls: true,
    src: recordedAudio
  }) : (0, _preact.h)("canvas", {
    ref: canvasEl,
    className: "uppy-Audio-canvas"
  })), (0, _preact.h)("div", {
    className: "uppy-Audio-footer"
  }, (0, _preact.h)("div", {
    className: "uppy-Audio-audioSourceContainer"
  }, shouldShowAudioSourceDropdown ? AudioSourceSelect(props) : null), (0, _preact.h)("div", {
    className: "uppy-Audio-buttonContainer"
  }, shouldShowRecordButton && (0, _preact.h)(RecordButton, {
    recording: recording,
    onStartRecording: onStartRecording,
    onStopRecording: onStopRecording,
    i18n: i18n
  }), hasRecordedAudio && (0, _preact.h)(SubmitButton, {
    onSubmit: onSubmit,
    i18n: i18n
  }), hasRecordedAudio && (0, _preact.h)(DiscardButton, {
    onDiscard: onDiscardRecordedAudio,
    i18n: i18n
  })), (0, _preact.h)("div", {
    className: "uppy-Audio-recordingLength"
  }, !hasRecordedAudio && (0, _preact.h)(RecordingLength, {
    recordingLengthSeconds: recordingLengthSeconds,
    i18n: i18n
  }))));
}

module.exports = RecordingScreen;