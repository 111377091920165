module.exports = function calculateProcessingProgress(files) {
  var values = [];
  var mode;
  var message;

  for (var _i = 0, _Object$values = Object.values(files); _i < _Object$values.length; _i++) {
    var progress = _Object$values[_i].progress;
    var preprocess = progress.preprocess,
        postprocess = progress.postprocess; // In the future we should probably do this differently. For now we'll take the
    // mode and message from the first file…

    if (message == null && (preprocess || postprocess)) {
      var _ref = preprocess || postprocess;

      mode = _ref.mode;
      message = _ref.message;
    }

    if ((preprocess == null ? void 0 : preprocess.mode) === 'determinate') values.push(preprocess.value);
    if ((postprocess == null ? void 0 : postprocess.mode) === 'determinate') values.push(postprocess.value);
  }

  var value = values.reduce(function (total, progressValue) {
    return total + progressValue / values.length;
  }, 0);
  return {
    mode: mode,
    message: message,
    value: value
  };
};