var secondsToTime = require('./secondsToTime');

module.exports = function prettyETA(seconds) {
  var time = secondsToTime(seconds); // Only display hours and minutes if they are greater than 0 but always
  // display minutes if hours is being displayed
  // Display a leading zero if the there is a preceding unit: 1m 05s, but 5s

  var hoursStr = time.hours === 0 ? '' : "".concat(time.hours, "h");
  var minutesStr = time.minutes === 0 ? '' : "".concat(time.hours === 0 ? time.minutes : " ".concat(time.minutes.toString(10).padStart(2, '0')), "m");
  var secondsStr = time.hours !== 0 ? '' : "".concat(time.minutes === 0 ? time.seconds : " ".concat(time.seconds.toString(10).padStart(2, '0')), "s");
  return "".concat(hoursStr).concat(minutesStr).concat(secondsStr);
};